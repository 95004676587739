@use '@kronoss/styles/globals' as *;
:root {
  --background: #f9fafb;
  --foreground: #16325b;
}

@media (prefers-color-scheme: dark) {
  :root {
    //--background: #0a0a0a;
    //--foreground: #ededed;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: var(--foreground);
  background: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .hot-toast {
    > * {
      > * {
        padding: 0;
        background: none;
        max-width: fit-content;

        > * {
          margin: 0;
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea {
  font-family: Inter, sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
